import React from 'react';

export const scrollToElement = (headerRef: React.RefObject<HTMLElement>, offset: number = 0): void => {
  if (headerRef.current) {
    const elementPosition = headerRef.current.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.scrollY - offset;
    window.scrollTo({
      top: offsetPosition,
      behavior: 'auto', 
    });
  }
};
