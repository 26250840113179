import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Pagination from "@mui/material/Pagination";

import Toggle from "../../utilComponents/toggle/Toggle";
import { scrollToElement } from "../../utilComponents/scroll/scrollToElement.tsx";

import { Link } from "react-router-dom";

import LoadingCards from "../loadingCards/LoadingCards";
import PlayerCard from "../playerCard/PlayerCard";
import EmptyView from "../emptyView/EmptyView";
import ArticleList from "../articleList/ArticleList";
import JobsList from "../jobsList/JobsList";

import "./PlayerCardCollection.scss";

const emptyViewStyles = {
  position: "absolute",
  top: "50%",
  left: "23%",
  fontSize: "2em",
};

function PlayerCardCollection({
  fellows,
  fellowCount,
  userType,
  subdomain,
  loading,
}) {
  const history = useHistory();
  const urlSearch = new URLSearchParams(history.location.search);
  const orderBySearchParam = urlSearch.get("sortBy");
  const nameFilterSearchParam = urlSearch.get("nameFilter");
  const showEmployedSearchParam = !!urlSearch.get("employed");
  const [searchTerm, setSearchTerm] = useState(nameFilterSearchParam);
  const [orderBy, setOrderBy] = useState(orderBySearchParam || "pointsAllTime");
  const debounced = useDebouncedCallback((changeEvent) => {
    handleNameFilterChange(changeEvent);
  }, 300);


  const headerRef = useRef(null);



  const handleOrder = (e) => {
    setOrderBy(e.target.value);
    urlSearch.set("sortBy", e.target.value);
    urlSearch.set("page", 1);
    history.replace({ search: urlSearch.toString() });
    scrollToElement(headerRef, 50);
  };

  const handleChangeEmployed = () => {
    if (showEmployedSearchParam) {
      urlSearch.delete("employed");
    } else {
      urlSearch.set("employed", "true");
    }
    urlSearch.set("page", 1);
    history.replace({ search: urlSearch.toString() });
    scrollToElement(headerRef, 50);
  };
  const handleNameFilterChange = (e) => {
    setSearchTerm(() => {
      urlSearch.set("nameFilter", e.target.value.toLowerCase());
      if (e.target.value.length === 0) {
        urlSearch.delete("nameFilter");
      }
      urlSearch.set("page", 1);
      history.replace({ search: urlSearch.toString() });
      scrollToElement(headerRef, 50);
      return e.target.value.toLowerCase();
    });
  };

  const playerCards = fellows.map((userInfo, index) => {
    return (
      <Link
        key={userInfo.name + index}
        to={{
          pathname: "/fellow/" + userInfo.id,
          state: {
            playerInfo: userInfo,
          },
        }}
      >
        <PlayerCard
          key={userInfo.name + index}
          playerInfo={userInfo}
          place={index}
        />
      </Link>
    );
  });

  // add article list to player cards at the second index
  playerCards.splice(2, 0, <ArticleList key="articleList" dropShadow home />);
  // playerCards.splice(7, 0, <JobsList key="jobsList" dropShadow />)

  return (
    <div className="playerCardCollection" id="players">
      <div className="playerCardCollection__header" ref={headerRef}>
        <h1 >
          {userType || "Coders"} ({fellowCount})
        </h1>
        <div className="playerCardCollection__order">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Order by:</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={orderBy}
              label="Order By"
              onChange={handleOrder}
              variant="outlined"
            >
              <MenuItem defaultValue={true} value={"pointsAllTime"}>
                Points All Time
              </MenuItem>
              <MenuItem value={"pointsThisWeek"}>Points This Week</MenuItem>
              <MenuItem value={"pointsThisMonth"}>Points This Month</MenuItem>
              <MenuItem value={"commitsThisWeek"}>Commits This Week</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="playerCardCollection__filter">
          <TextField
            id="search-user"
            label="Search"
            variant="outlined"
            defaultValue={nameFilterSearchParam || ""}
            onChange={(e) => debounced(e)}
          />
          {subdomain !== "codetrack" && (
            <div className="playerCardCollection__showEmployed">
              <div className="playerCardCollection__showEmployedText">
                Include Employed
              </div>
              <Toggle
                toggleOn={showEmployedSearchParam}
                setToggleOn={handleChangeEmployed}
              />
            </div>
          )}
        </div>
      </div>

      <div className="playerCardCollection__container">
        {loading && <LoadingCards count={6} />}
        {playerCards}
      </div>

      {fellowCount > 0 && (
        <Pagination
          count={Math.ceil(fellowCount / 50)}
          page={Number(urlSearch.get("page") || 1)}
          color="primary"
          size="large"
          sx={{
            bgcolor: "inherit",
            display: "flex",
            justifyContent: "center",
            minWidth: "375px",
          }}
          onChange={(_, page) => {
            urlSearch.set("page", page);
            history.replace({
              search: urlSearch.toString(),
            });
            scrollToElement(headerRef, 50);
          }}
        />
      )}
      {(fellows.length < 1 ||
        (!fellows.length && searchTerm) ||
        fellows.length === 0) &&
        !loading && <EmptyView styles={emptyViewStyles} text={"No Results"} />}
    </div>
  );
}
export default PlayerCardCollection;