import React from 'react';

// STYLES
import "./EmptyView.scss";

interface EmptyViewProps {
    text?: string;
    styles?: React.CSSProperties;
  }

function EmptyView({text, styles}: EmptyViewProps): JSX.Element {

    return (
        <div className="emptyView" style={styles} data-testid="emptyView">
            {text ? text : "No Items"}
        </div>
    );
}

export default EmptyView;

