
import React, { useRef, useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import queryString from "query-string";

import "./StickyNavbar.scss";

import ClassSelector from "../../components/classSelector/ClassSelector";
import BumpChart from "../../components/bumpChart/BumpChart";

import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const useDetectSticky = (ref, observerSettings = { threshold: [1] }) => {
  const [isSticky, setIsSticky] = useState(false);
  const newRef = useRef();
  ref ||= newRef;

  // mount
  useEffect(() => {
    const cachedRef = ref.current,
      observer = new IntersectionObserver(
        ([e]) => setIsSticky(e.intersectionRatio < 1),
        observerSettings
      );

    observer.observe(cachedRef);

    // unmount
    return () => {
      observer.unobserve(cachedRef);
    };
  }, []);

  return [isSticky, ref, setIsSticky];
};

const SelectedCohortPills = ({ cohorts, removeCohort }) => {
  return (
    <div className="stickyNavbar__cohortPills">
      {cohorts.map((cohort, index) => {
        return (
          <div className="stickyNavbar__cohortPill" id={cohort} key={index}>
            {cohort}
            <HighlightOffIcon
              className="stickyNavbar__cohortPillClose"
              id={cohort}
              onClick={removeCohort}
            />
          </div>
        );
      })}
    </div>
  );
};

const StickyNavbar = ({ subdomain, cohorts, setCohorts, orgConfig }) => {
  let history = useHistory();
  let location = useLocation();

  const [isSticky, ref, setIsSticky] = useDetectSticky();
  const [subView, setSubView] = useState("");

  // Set initial cohorts from URL on mount
  useEffect(() => {
    const parsed = queryString.parse(location.search);
    if (parsed.cohort) {
      setCohorts(parsed.cohort.split(","));
    }
  }, [location.search, setCohorts]);

  useEffect(() => {
    setSubView("");
  }, [isSticky]);

  const handleSubViews = (e) => {
    const subViewType = e.target.id;
    if (subViewType === subView) {
      setSubView("");
    } else {
      setSubView(subViewType);
    }
  };

  const toggleCohort = (add, cohort) => {
    const parsed = queryString.parse(history.location.search);
    let selectedCohorts = [...cohorts]; // Use the current cohorts

    if (cohorts.length === 5) {
      add = false;
    }

    if (add) {
      selectedCohorts.push(cohort);
      selectedCohorts.sort((a, b) => {
        const aNum = parseFloat(a.split("-")[1]);
        const bNum = parseFloat(b.split("-")[1]);
        return aNum - bNum;
      });
    } else {
      selectedCohorts = selectedCohorts.filter((c) => c !== cohort);
    }

    setCohorts(selectedCohorts);

    if (selectedCohorts.length) {
      parsed.cohort = selectedCohorts.toString();
    } else {
      delete parsed.cohort;
    }

    let updatedQueryString = queryString.stringify(parsed);
    updatedQueryString = new URLSearchParams(updatedQueryString).toString();
    history.replace({ search: updatedQueryString });
  };

  const scrollToStaff = () => {
    setSubView("");
    const staff = document.getElementById("staff");
    staff.scrollIntoView({ behavior: "smooth" });
  };

  const removeCohort = (e) => {
    const cohort = e.currentTarget.id;
    toggleCohort(false, cohort);
  };

  return (
    <div
      className={"stickyNavbar" + (isSticky ? " stickyNavbar--isSticky" : "")}
      ref={ref}
    >
      <ul className="stickyNavbar__items">
        <li
          className={
            "stickyNavbar__item" +
            (subView === "cohorts" ? " stickyNavbar__item--active" : "")
          }
          id="cohorts"
          onClick={(e) => handleSubViews(e)}
        >
          Filter By Cohort
        </li>
        <li className="stickyNavbar__item" onClick={scrollToStaff}>
          Staff
        </li>
        <li
          className={
            "stickyNavbar__item" +
            (subView === "standings" ? " stickyNavbar__item--active" : "")
          }
          id="standings"
          onClick={(e) => handleSubViews(e)}
        >
          Show Standings
        </li>
        <SelectedCohortPills cohorts={cohorts} removeCohort={removeCohort} />
      </ul>
      <div
        className="stickyNavbar__subview"
        style={{ display: subView ? "block" : "none" }}
      >
        {subView === "cohorts" ? (
          <ClassSelector
            subdomain={subdomain}
            orgConfig={orgConfig}
            cohorts={cohorts}
            setCohorts={setCohorts}
            toggleCohort={toggleCohort}
          />
        ) : (
          <></>
        )}
        {subView === "standings" ? <BumpChart subdomain={subdomain} /> : <></>}
      </div>
    </div>
  );
};

export default StickyNavbar;
