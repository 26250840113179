//COMPONENTS
import { PlayerInfo } from "../components/playerCard/PlayerCard";

export interface UserResponse {
    status: string;
    users: PlayerInfo[];
    count: number;
    employed: boolean;
  }
  
export const fetchUsersOfOrganizationWithPagination = async (
    organization: string,
    page: number = 1,
    cohort?: string | string[], 
    employed?: boolean,
    searchTerm?: string,
    limit?: number,
    sortBy?: string
  ): Promise<UserResponse> => {
    try {
      const url = new URL(`${process.env.REACT_APP_API}/users`);
      
      // Add base parameters
      url.searchParams.set("organization", organization);
      url.searchParams.set("page", page.toString());

      // Handle name filter parameter
      if (searchTerm) {
        url.searchParams.set("nameFilter", searchTerm);
      }
      // Handle employed parameter
      if (employed !== undefined) {
        url.searchParams.set("employed", employed.toString());
      }

      if (limit) {
        url.searchParams.set("limit", limit.toString());
      }

      if (sortBy) {
        url.searchParams.set("sortBy", sortBy);
      }
      
      // Handle cohort parameter
      if (cohort) {
        // If cohort is an array, join with comma
        const cohortValue = Array.isArray(cohort) ? cohort.join(',') : cohort;
        url.searchParams.set("cohort", cohortValue);
      }
      
      // Get any existing query parameters from the current URL
      const currentParams = new URLSearchParams(window.location.search);
      
      for (const [key, value] of currentParams) {
        // Skip parameters we've already set
        if (!['organization', 'page', 'cohort'].includes(key)) {
          url.searchParams.set(key, value);
        }
      }
      
      const response = await fetch(url.toString());
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const data: UserResponse = await response.json();
      
      if (data.status !== "success") {
        throw new Error(data.status || "Failed to fetch users");
      }
      
      return data;
      
    } catch (error) {
      console.error("Error fetching users:", error);
      throw error;
    }
};

