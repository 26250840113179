import React from 'react';

//STYLES
import './LoadingCards.scss';

type LoadingCardsProps = {
    count: number;
}

function LoadingCards({count}: LoadingCardsProps) {

    let loadingCards = [];

    for(let i = 0; i < count; i++){
        loadingCards.push(<div key={i} className="loadingCard" id="gradient"></div>)
    }

    return (
        <>
           {loadingCards}
        </>
    );
}

export default LoadingCards;
