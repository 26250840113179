import React, { useState, useEffect, Suspense } from "react";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";

import { fetchUsersOfOrganizationWithPagination } from "../services/usersService";

import Snackbar from "@mui/material/Snackbar";

import StickyNavbar from '../layout/stickyNavbar/StickyNavbar';
import TopScoresCollection from '../components/topScoresCollection/TopScoresCollection';
import PlayerCardCollection from '../components/playerCardCollection/PlayerCardCollection';
import ScoreBoard from '../components/scoreBoard/ScoreBoard';

const StaffCardCollection = React.lazy(() =>
  import("../components/staffCardCollection/StaffCardCollection")
);

function Home({
  subdomain = "codetrack",
  loggedIn,
  setLoggedIn,
  userType,
  orgConfig = {},
}) {
  let history = useHistory();

  const location = useLocation();

  const parsed = queryString.parse(location.search);

  if (parsed && parsed.accessToken) {
    localStorage.setItem("accessToken", location.search.split("=")[1]);
    setLoggedIn(true);
    history.push("/");
  }

  const [fellows, setFellows] = useState([]);
  const [fellowCount, setFellowCount] = useState(0);
  const [cohorts, setCohorts] = useState([]);
  const [employedFellows, setEmployedFellows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [toastNote, setToastNote] = useState(location?.state?.toastMessage);
  const [showToast, setShowToast] = useState(!!location?.state?.toastMessage);

  useEffect(() => {
    
    const fetchUsers = async () => {
      try {
        const params = new URLSearchParams(window.location.search);
        const cohort = params.get('cohort');
        const page = parseInt(params.get('page') || '1');
        
        const result = await fetchUsersOfOrganizationWithPagination(
          subdomain,
          page,
          cohort ? cohort.split(',') : undefined
        );
        
        setFellows(result.users);
        setFellowCount(result.count);
      } catch (error) {
        setToastNote("An error occurred in fetching users.");
        setShowToast(true);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();

  }, [location, subdomain]);

  return (
    <div className="home">
      <Snackbar
        open={showToast}
        autoHideDuration={2000}
        onClose={() => setShowToast(!showToast)}
        message={toastNote}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      />
      <ScoreBoard subdomain={subdomain} />
      {subdomain !== "codetrack" && (
        <StickyNavbar
          subdomain={subdomain}
          orgConfig={orgConfig}
          cohorts={cohorts}
          setCohorts={setCohorts}
        />
      )}
      <TopScoresCollection
        loading={loading}
        location={location}
        fellows={fellows}
        subdomain={subdomain}
      />
      <PlayerCardCollection
        userType={userType}
        cohorts={cohorts}
        fellows={fellows}
        fellowCount={fellowCount}
        setFellows={setFellows}
        setEmployedFellows={setEmployedFellows}
        loading={loading}
        subdomain={subdomain}
      />

      <Suspense fallback={<div>Loading...</div>}>
        <StaffCardCollection cohorts={cohorts} subdomain={subdomain} />
      </Suspense>
    </div>
  );
}
export default Home;
