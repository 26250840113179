import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import './Modal.scss';


interface ModalProps {
  setShowModal: (show: boolean) => void;
  showModal: boolean;
  title?: string;
  customStyling?: boolean;
  showSkipButton?: boolean;
  children: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({ setShowModal, showModal, title, customStyling, showSkipButton, children }) => {

  const hideModal = () => {
    setShowModal(!showModal);
  };

  const displayStyling: React.CSSProperties = { display: showModal ? "block" : "none" };

  const modalStyling: React.CSSProperties = {
    ...displayStyling,
    padding: customStyling ? "0px" : undefined,
    maxWidth: customStyling ? "740px" : undefined,
    top: customStyling ? "85px": undefined
  };

  return (
    <>
      <div data-testid="remove-member-modal" className="modal__overlay" onClick={hideModal} style={displayStyling}></div>
      <div className="modal" style={modalStyling}>
        {showSkipButton ? (
          <p className="modal__skipButton" onClick={hideModal}>
            Skip &gt;
          </p>
        ) : (
          <div className="modal__closeIcon" onClick={hideModal}>
             <FontAwesomeIcon icon={faTimes as any} size="lg" />
          </div>
        )}
        <div className="modal__title">{title}</div>
        <div className="modal__content">
          {children}
        </div>
      </div>
    </>
  );
}

export default Modal;
