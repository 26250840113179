import React from 'react';

//COMPONENTS
import { PlayerInfo } from '../playerCard/PlayerCard';

// STYLES
import './PlayerStats.scss';


interface PlayerStatsProps {
    playerInfo: PlayerInfo;
    title: string;
    type?: string;
}


function PlayerStats({playerInfo, title, type}: PlayerStatsProps): JSX.Element {

    return (
        <div className="playerStats">
            <span className="playerStats__title">{title}</span>
            <div className="playerStats__lastWeek">
                 <span 
                    className={playerInfo.points_this_week > 999 ? "playerStats__points playerStats__points--small" : "playerStats__points"}
                >
                    {type === 'codewars' ? playerInfo.points_this_week : playerInfo.commits_this_week }
                </span>
                 <div className="playerStats__lastWeekLabel" >This Week</div>
            </div>
            <div className="playerStats__lastMonth">
                <span 
                    className={playerInfo.points_this_month > 999 ? "playerStats__points playerStats__points--small" : "playerStats__points"}
                >
                    {type === 'codewars' ? playerInfo.points_this_month : playerInfo.commits_this_month }</span>
                <div className="playerStats__lastMonthLabel" >This Month</div>
            </div>
        </div>   
    );
}

export default PlayerStats;
